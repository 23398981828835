import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./style.css"

export interface LandingProjectRow {
  id: string
  frontmatter: {
    postThumbnail: string
    title: string
    description: string
    tags: string[]
    source?: string
    demo?: string
  }
  fields: {
    slug: string
  }
}

const LandingProject: React.FC<{}> = ({}) => {
  const data: {
    allMarkdownRemark: { nodes: ReadonlyArray<LandingProjectRow> }
  } = useStaticQuery(graphql`
    query recentProjects {
      allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { frontmatter: { templateKey: { eq: "project" } } }
      ) {
        nodes {
          frontmatter {
            postThumbnail
            description
            tags
            title
            source
            demo
          }
          id
          fields {
            slug
          }
        }
      }
    }
  `)

  return (
    <section id="projects" className="projects">
      <div className="container">
        <div className="section-title">
          <h2>Projects</h2>
        </div>
        <div className="grids">
          {data.allMarkdownRemark.nodes.map(row => {
            return (
              <article key={row.id} className="card " data-aos="fade-up">
                <Link to={row.fields.slug}>
                  {!!row.frontmatter.postThumbnail && (
                    <img
                      src={row.frontmatter.postThumbnail}
                      alt={row.frontmatter.title + "- Featured Shot"}
                    />
                  )}
                </Link>
                <header>
                  <h2 className="post-title">
                    <Link to={row.fields.slug} className="post-link">
                      {row.frontmatter.title}
                    </Link>
                  </h2>
                  <p className="post-description">
                    {row.frontmatter.description}
                  </p>
                  <div className="mt-5">
                    {row.frontmatter.source &&
                      row.frontmatter.source !== "none" && (
                        <a
                          href={row.frontmatter.source}
                          target="_blank"
                          className="mr-4"
                        >
                          Source
                        </a>
                      )}
                    {row.frontmatter.demo && row.frontmatter.demo != "none" && (
                      <a
                        href={row.frontmatter.demo}
                        target="_blank"
                        className="mr-4"
                      >
                        Website
                      </a>
                    )}
                  </div>
                </header>
              </article>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default LandingProject
