import React from "react"
import "./style.css"

const Contact: React.FC<{}> = ({}) => {
  return (
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-title"  data-aos="fade-up">
          <h2>Contact</h2>
          <a href="mailto:hello@debojitroy.com">hello@debojitroy.com</a>
        </div>
      </div>
    </section>
  )
}

export default Contact
