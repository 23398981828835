import React from "react"

import PageLayout from "../components/PageLayout/PageLayout"
import SEO from "../components/SEO/SEO"
import About from "../components/About/About"
import LandingBlogs from "../components/Blog/LandingBlogs"
import LandingProjects from "../components/Projects/LandingProjects"
import Contact from "../components/Contact/Contact"

const IndexPage = () => (
  <PageLayout showHero>
    <SEO />
    <About />
    <LandingBlogs />
    <LandingProjects />
    <Contact />
  </PageLayout>
)

export default IndexPage
