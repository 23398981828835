import React from "react"
import "./style.css"

const About: React.FC<{}> = () => {
  return (
    <>
      <section id="about" className="about">
        <div className="container">
          <div className="section-title">
            <h2>About</h2>
            <p>
              Dynamic, Software Architect, Full Stack Developer, Techno
              Managerial leader with 14+ years of experience in building and
              delivering large scale applications across start-ups, mid-size and
              large organizations with proven record of building teams from
              scratch and mentoring upcoming mid-management leaders. Skilled at
              managing small and large software projects from design to delivery
              used by millions of retail and corporate users.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4" data-aos="fade-right">
              <img
                src="assets/debo-profile-002.jpg"
                className="img-fluid"
                alt="Debojit Roy Profile Photo"
              />
            </div>
            <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
              <h3>FULL STACK DEVELOPER / SOFTWARE ARCHITECT</h3>
              <p className="font-italic"></p>
              <div className="row">
                <div className="col-lg-12">
                  <ul>
                    <li>
                      <i className="icofont-rounded-right"></i>
                      <strong>Value Creator:</strong> Valued Contributor in the
                      areas of Software Development, Vendor Management,
                      Strategic Planning, Product Design, Program and Project
                      Management and known as an innovative negotiator who
                      demonstrates high level of integrity with a history of
                      successfully developing and growing profitable categories
                      by building long term relationships both internally and
                      externally
                    </li>
                    <li>
                      <i className="icofont-rounded-right"></i>
                      <strong>Software Architect:</strong> Helped companies
                      transform into technological leaders by choosing the right
                      technologies and tools for the business and creating an
                      example for competition to follow. Helped traditional
                      businesses​ come closer to their customers by going online
                      and adopting cloud for better infrastructure and
                      continuous content delivery.
                    </li>
                    <li>
                      <i className="icofont-rounded-right"></i>
                      <strong>Full Stack Developer:</strong> Chose the right
                      team setup and best technologies for the job at hand.
                      Helped larger teams align by roles, responsibility and
                      regions. Made the right choice of technology for long term
                      benefits, sustainability and code management.
                    </li>
                  </ul>
                </div>
              </div>
              <p>
                Proven bottom line focus, managing projects with cross
                functional and diverse teams exceeding business goals
                contributing to a positive work environment and promoting
                teamwork; supporting/leading efforts to increase employee
                engagement.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="skills" className="skills section-bg">
        <div className="container">
          <div className="section-title">
            <h2>Skills</h2>
            <p>
              Experienced in delivering projects across various tech stacks and
              setups.
            </p>
          </div>

          <div className="row skills-content">
            <div className="col-lg-6" data-aos="fade-up">
              <div className="progress">
                <span className="skill">
                  Frontend <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: '100%', }}
                    aria-valuenow={100}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  Frontend Frameworks <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: '100%', }}
                    aria-valuenow={100}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  Backend <i className="val">90%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: '90%', }}
                    aria-valuenow={90}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  Database {" "}
                  <i className="val">75%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: '75%', }}
                    aria-valuenow={75}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  NoSQL {" "}
                  <i className="val">75%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: '75%', }}
                    aria-valuenow={75}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>

            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <div className="progress">
                <span className="skill">
                  Cloud <i className="val">70%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: '70%', }}
                    aria-valuenow={70}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About
